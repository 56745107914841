import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router'

import get from 'lodash/get'

import { Categories, MatchCategories } from 'Components/Blocks'
import { Loader } from 'Components/UI'

import getCategoriesByTags from 'Services/Utils/getCategoriesByTags'

import { Container, LoaderHolder } from './styles'

function List({ allCategories, isLoadingAllCategories }) {
  const location = useLocation()
  const categories = get(allCategories, 'categories', [])

  const matchCategoriesByTags = getCategoriesByTags({ categories, location })

  return (
    <Container>
      <Categories categories={categories} />
      <MatchCategories categories={matchCategoriesByTags} />
      {isLoadingAllCategories && (
        <LoaderHolder>
          <Loader size={26} />
        </LoaderHolder>
      )}
    </Container>
  )
}

List.defaultProps = {
  allCategories: {},
}

List.propTypes = {
  allCategories: PropTypes.object,
  isLoadingAllCategories: PropTypes.bool.isRequired,
}

export default List
