import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from 'Components/UI'

export const Container = styled(Flex).attrs({
  flexDirection: 'column-reverse',
  alignItems: 'center',
})`
  width: 100%;
  background: ${themeGet('colors.pearl')};
`

export const LoaderHolder = styled(Flex).attrs({ py: 3 })`
  position: relative;
`
