import { useMemo } from 'react'

import get from 'lodash/get'
import reduce from 'lodash/reduce'
import find from 'lodash/find'
import map from 'lodash/map'
import size from 'lodash/size'

import * as Utils from 'Services/Utils'

export default ({ categories, location }) => {
  return useMemo(() => {
    const tagSlug = Utils.getTagSlugFromLocation(location)

    if (size(categories) && tagSlug) {
      return reduce(
        categories,
        (accPrimary, category) => {
          const secondaryCategories = get(category, 'category_2', [])
          const nextCategory = {
            ...category,
            category_2: [],
          }

          if (secondaryCategories.length) {
            map(secondaryCategories, secondaryCategory => {
              const secondaryCategoryTags = get(secondaryCategory, 'tags', [])
              const findTag = find(secondaryCategoryTags, tag =>
                tagSlug.includes(Utils.slugify(tag.name)),
              )
              if (findTag) {
                nextCategory.category_2.push(secondaryCategory)
              }
            })
          }

          if (nextCategory.category_2.length) {
            return [...accPrimary, nextCategory]
          }
          return accPrimary
        },
        [],
      )
    }
    return null
  }, [categories, location.hash])
}
